<template>
  <div>
    <div class="page-nav">
      <i class="el-icon-s-unfold"></i>
      <span>{{`Tower - ${towerCode} / Upload Camera`}}</span>
    </div>
    
    <list-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      towerCode: '',
      options: [],
      // 电塔日期信息
      cameraList: [],
    }
  },

  components: {
    ListView: () => import('./components/tower-camera-list'),
  },

  created() {
    this.towerCode = this.$route.query.towerCode
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.page-nav {
  margin-bottom: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  i {
    margin-right: 15px;
  }
  span {
    font-weight: 600;
  }
}
.home-page {
  &__header {
    &__operate {
      width: var(--main-width);
      margin-bottom: 35px;
      .input-rem {
        height: 36px;
        line-height: 36px;
      }

      &__form-box {
        & > * {
          margin-right: 20px;
          line-height: 36px;

          ::v-deep .el-input__inner {
            height: 100%;
          }
        }
        & > ::v-deep .el-input {
          width: 325px;
          .el-input__inner {
            border-color: #979797;
            color: var(--color-black);
            &::placeholder {
              color: #979797;
            }
          }
        }

        & .el-button {
          color: var(--color-white);
          text-align: center;
          padding:0 10px !important;
        }

        & > ::v-deep .el-select {
          width: 160px;

          .el-input {
            height: 100%;
          }
          .el-input__inner {
            color: var(--color-black);
            border: none;
            border-bottom: 2px solid var(--color-info);
            border-radius: 0px;
            background: rgba(0, 0, 0, 0);
            padding: 0;
            &::placeholder {
              color: var(--color-black);
            }
          }
          & .el-select__caret.el-icon-arrow-up::before {
            content: '\e78f';
            color: var(--color-black);
          }

          .el-input__icon {
            line-height: 1;
          }
        }
      }

      &__buttons {
        transition: all 0.3s ease;

        .el-button {
          padding: 0px 15px;
        }
      }
    }
  }
}
</style>